import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComingSoon from "./pages/ComingSoon";

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<ComingSoon />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
