import React from "react";

const ComingSoon = () => {
  return (
    <>


      {/* Hero section start */}
      <section className="banner-section">
        <div className="pt-120">
          <div className="banner-content d-flex align-items-center">
            <div className="container-fluid">
              <div className="row justify-content-start">
                <div className="col-lg-12 col-md-12 text-center">
                  <div className="main-content pt-60">
                    <div className="top-area section-text justify-content-center">
                      <h3>Coming-Soon</h3>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hero section end */}

    </>
  );
}
export default ComingSoon;
